import axios from 'axios';
import authService from './authService';
const token = authService.getAccessToken();
class StaffingServices {
  getJobs  = () => new Promise((resolve, reject) => {
    axios.get(`https://connect.medtigo.com/api/v1/staffing/jobs`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  addResumeData  = (values, fileUploadAction) => new Promise((resolve, reject) => {
    axios.post('https://connect.medtigo.com/api/v1/staffing/formSubmit/add',
      values, // The data payload
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        ...fileUploadAction // Other configurations like file upload should go here
      }
    )
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  getJobSave  = (id) => new Promise((resolve, reject) => {
 
    axios.get(`https://connect.medtigo.com/api/v1/staffing/save/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
 
  recordDropArrow  = (id) => new Promise((resolve, reject) => {
 
    axios.get(`https://connect.medtigo.com/api/v1/staffing/detailView/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  getBookmarkJob  = () => new Promise((resolve, reject) => {
  
    axios.get(`https://connect.medtigo.com/api/v1/staffing/getstatus`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  
  geIntendedJob  = (id) => new Promise((resolve, reject) => {
    axios.get(`https://connect.medtigo.com/api/v1/staffing/getIntendedstatus/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  geIntendedJobs  = (id) => new Promise((resolve, reject) => {
    axios.get(`https://connect.medtigo.com/api/v1/staffing/getIntendedjobs`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}



const staffingServices = new StaffingServices();
export default staffingServices;
