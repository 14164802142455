import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, Tabs, Tab, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HeaderMenu from './HeaderMenu';
import Backdrop from './Backdrop';
import history from 'src/utils/history';
import authService from 'src/services/authService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    fontSize: '16px',
    fontFamily: 'Poppins',
    marginBottom: '5px',
    boxShadow: '0px 4px 4px 0px #00000014',
  },
  toolbarContainer: {
    maxWidth: 1440,
    margin: '0 auto',
    width: '100%',
  },
  bottomToolbar: {
    backgroundColor: '#fff',
    display: 'flex',
    fontFamily: 'Poppins',
    fontWeight: 500,
    paddingInline: '40px',
    [theme.breakpoints.down('md')]: {
      paddingInline: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingInline: '16px',
    },
  },
  flexGrow: {
    flexGrow: 1
  },
  divider: {
    width: '1px',
    height: 'inherit',
    backgroundColor: '#ddd'
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '9px'
  },
  tab: {
    color: '#000',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    height: '64px',
    minWidth: 'auto',
    width: 'auto',
    paddingInline: '0px !important',
    marginLeft: '20px !important',
    '&.Mui-selected': {
      color: '#2872C1'
    }
  },
  leftSection: {
    display: 'flex',
    gap: '17px',
    [theme.breakpoints.down('xs')]: {
      gap: 10
    }
  },
  rightSection: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center'
  },
  tabIndicator: {
    color: '#2872C1',
    backgroundColor: '#2872C1',
    height: '3px',
    bottom: 0
  },
  menu: {
    color: '#2872C1',
    fontSize: 35
  }
}));

const tabs = [
  { value: 'home', label: 'Home', link: 'https://staging17.medtigo.com/' },
  { value: 'news', label: 'News & Opinion', link: 'https://staging17.medtigo.com/news/' },
  { value: 'reference', label: 'Reference', link: 'https://staging17.medtigo.com/drugs/' },
  { value: 'journal', label: 'Journals', link: 'https://staging2.journal.medtigo.com/' },
  { value: 'education', label: 'Education', link: 'https://staging2.courses.medtigo.com/' },
  { value: 'services', label: 'Services', link: '/services' }
];

function MidBar() {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('services');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [functionalTabs, setFunctionalTabs] = useState(tabs)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { user } = useSelector(state => state.account);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleClickservice = () => {

    history.push('/dashboard');

  };

  const handleTabClick = (event, tab) => {
    event.preventDefault();
  
    // Only show the backdrop for non-services tabs
    if (tab.value !== 'services') {
      setOpenBackdrop(true);   
    }
  
    if (tab.value === 'services') {
      history.push('/dashboard');
    } else {
      window.location.href = tab.link;
    }
  };

  const getCoursesToken = async () => {
    try {
      const newToken = await authService.generateToken(); // create a new 24 hour token
      return newToken;
    } catch (error) {
      console.error('Error generating token:', error);
      return null;
    }
  };  

  const handleLogoClick = async () => {
    const connectTokensForWordpress = await getCoursesToken();
    const courses_connect_token = connectTokensForWordpress ? "&t1=" + connectTokensForWordpress : '';
    window.location.href = 'https://medtigo.com/?t=' + user.wp_token + courses_connect_token;
  };
  

  useEffect(() => {
    (async () => {
      const connectTokensForWordpress = await getCoursesToken();
      const courses_connect_token = connectTokensForWordpress ? "&t1=" + connectTokensForWordpress : '';
      setFunctionalTabs(...[functionalTabs.map(tab => {
          if(tab.value === 'services') return tab
          return {...tab, link: tab.link+'?t='+ user.wp_token + courses_connect_token}
        })])
    })()
  }, [])

  return (
    <AppBar className={clsx(classes.root)} position="static" elevation={0}>
      <div className={classes.toolbarContainer}>
        <Toolbar className={classes.bottomToolbar}>
          <div className={classes.leftSection}>
            <img 
              src="https://medtigo.com/wp-content/uploads/2024/05/medtigo_2-1.svg"
              alt="Logo"
              height="50px"
              width="168px"
              onClick={handleLogoClick}
              style={{ cursor: 'pointer' }} 
            />
          </div>
          <div className={classes.flexGrow}></div>
          {isMobile ? (
            <>
              <Typography onClick={handleClickservice} style={{color: 'black', fontSize: '16px', fontWeight: 600, paddingInline: '12px'}}>SERVICES</Typography>
              <MenuRoundedIcon className={classes.menu} onClick={handleClick} />
              <HeaderMenu
                tabs={functionalTabs}
                anchorEl={anchorEl}
                handleClose={handleClose}
                setOpenBackdrop={setOpenBackdrop}
              />
            </>
          ) : (
            <Tabs
              value={currentTab}
              onChange={handleTabsChange}
              textColor="secondary"
              classes={{ indicator: classes.tabIndicator }}
              className={classes.rightSection}
            >
              {functionalTabs.map(tab => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  className={classes.tab}
                  href={tab.link}
                  onClick={(event) => handleTabClick(event, tab)} 
                />
              ))}
            </Tabs>
          )}
        </Toolbar>
      </div>
      <Backdrop open={openBackdrop} setOpen={setOpenBackdrop}/>
    </AppBar>
  );
}

export default MidBar;
