import React from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root:{
    '& .MuiTypography-root, & .MuiLink-root, & .MuiButton-root': {
      [theme.breakpoints.between('sm', 'lg')]: {
        fontSize: '11.7px !important',
      },
  },
},
  footer: {
    backgroundColor: '#143961',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
  },
  section: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 40px', 
    maxWidth: '1440px',
    margin: '0 auto', 
    boxSizing: 'border-box', 
    [theme.breakpoints.down('md')]: {
      padding: '30px 32px', 
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 16px', 
      flexDirection: 'column',
    },
  },
  logo: {
    width: '200px',
    marginBottom: '6px',
    [theme.breakpoints.down('md')]: {
      width: '150px', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      width: '50% !important',
    },
  },
  socialIcon: {
    height: '30px',
    width: '30px',
    backgroundColor: '#466382',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: 'white !important',
    textDecoration: 'none !important',
    fontWeight: '300 !important',
    fontFamily: 'Poppins !important',
    fontSize: '15px !important',
    letterSpacing: '0.03em !important',
    lineHeight: '24px !important',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  contactlink: {
    color: 'white !important',
    textDecoration: 'underline !important',
    textDecorationColor: 'white !important',
    fontWeight: 300,
    fontFamily: 'Poppins',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  contactlinkNoUnderline: {
    color: 'white !important',
    textDecoration: 'none !important',
    fontWeight: 300,
    fontFamily: 'Poppins',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  title: {
    fontWeight: '600 !important',
    fontSize: '1.25rem !important',
    fontFamily: 'Poppins !important',
    letterSpacing: '0.03em !important',
    lineHeight: '27px !important',
    marginBottom: '25px !important',
    marginTop: '12px !important',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '14px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px !important', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  addressTitle: {
    fontWeight: '600 !important',
    fontFamily: 'Poppins !important',
    fontSize: '15px !important',
    letterSpacing: '0.03em !important',
    marginBottom: '8px !important',
    lineHeight: '24px !important',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px !important', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  address: {
    fontWeight: '300 !important',
    marginBottom: '20px !important',
    fontSize: '15px !important',
    fontFamily: 'Poppins !important',
    letterSpacing: '0.03em !important',
    lineHeight: '26px !important',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  contact: {
    fontWeight: '300 !important',
    fontSize: '15px !important',
    letterSpacing: '0.03em !important',
    lineHeight: '28px !important',
    fontFamily: 'Poppins !important',
    color: '#ffffff',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  appLink: {
    width: 36,
    height: 36,
    marginRight: '15px',
  },
  joinUs: {
    minWidth: '87px',
    backgroundColor: '#2872C1 !important',
    borderRadius: '3px !important',
    fontWeight: '600 !important',
    fontSize: '13px !important',
    marginBottom: '7px !important',
    padding: theme.spacing(1, 2),
    color: 'white',
    '&:hover': {
      backgroundColor: '#1e5c9e',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  copyright: {
    backgroundColor: '#10345A',
    padding: '16px',
    borderTop: '1px solid #8C8C8C',
    fontFamily: 'Poppins !important',
    fontSize: '15px !important',  
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important', // Adjust font size for medium screens
    },
  },
  copyrightText: {
    fontsize: '15px !important',
    fontWeight: '200 !important',
    fontFamily: 'Poppins !important',
    letterSpacing: '0.03em',
    textAlign: 'center',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  description: {
    fontFamily: 'Poppins !important',
    fontWeight: '300 !important',
    letterSpacing: '0.03em !important',
    marginBottom: '20px !important',
    lineHeight: '26px !important',
  
   // For screens below 1280px
  width: '200px',
  fontSize: '11.7px !important',

  // For screens 1280px and above
  [theme.breakpoints.up('1281')]: {
    width: '230px',
    fontSize: '15px !important',
  },
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    fontSize: '11.7px !important',
  },
    // For screens smaller than small (max 600px)
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      lineHeight: '20px !important',
      width: '100%',
    },
    // [theme.breakpoints.between('md', 'lg')]: {
    //   width: '200px',
    //   fontSize: '13px !important',
    // },
  },
  
  
  
  customHr: {
    borderBottom: 'var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)',
    borderColor: '#FFFFFF6E',
    width: '210px',
    marginTop: '17px !important',
    marginBottom: '17px !important',
    fontWeight: '300 !important',
    [theme.breakpoints.down('lg')]: {
      width: '180px !important', // Adjust for large screens and below
    },
    [theme.breakpoints.down('md')]: {
      width: '160px !important', // Adjust for medium screens and below
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Full width for mobile screens
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 2fr 2.6fr',
    gap: '40px',
    position: 'relative', // Added for absolute positioning of debug info

  
    '@media (min-width: 1280px)': {
  gridTemplateColumns: '2fr 2fr 2fr 2.8fr !important',
  gap: '40px !important',
},
    '@media (min-width: 600px) and (max-width: 1366px)': {
      gridTemplateColumns: '2fr 2fr 2fr 2.6fr',
      gap: '30px',
    },
   
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '2fr 2fr 2fr 2.6fr',
      gap: '60px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: '2fr 2fr 2fr 2.6fr',
      gap: '30px',
    },
    //  [theme.breakpoints.down('md')]: {
    //   gridTemplateColumns: '1fr 1fr',
    //   gap: '30px',
    // },
    // Mobile
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '20px',
    },
  },

  downloadApp: {
    marginRight: '18px !important',
    fontFamily: 'Poppins !important',
    fontWeight: '600 !important',
    letterSpacing: '0.03em',
    fontSize: '14px !important',
    marginTop: '10px !important',
    lineHeight: '24px !important',
    '@media (min-width: 600px) and (max-width: 1280px)': {
      fontSize: '11.7px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important', // Adjust for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      marginRight: '9px !important',
    },
  },
  CopyrightIcon: {
    marginRight:'.5rem !important',
    verticalAlign: 'sub',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px !important',
      verticalAlign: 'middle',
    },
  },
  MarginContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '0 !important',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0 !important',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <Box className={classes.section}>
          <Box style={{ width: '100%', margin: '0 auto', maxWidth: '1440px' }}>
            <Box className={classes.gridContainer}>
              <Box  className={classes.MarginContainer}>
                <Link href="https://medtigo.com/">
                  <img
                    src="https://medtigo.com/wp-content/uploads/2024/05/medtigo_2-1.svg"
                    alt="Medtigo Logo"
                    className={classes.logo}
                  />
                </Link>
                <Typography className={classes.description}>
                  Founded in 2014, medtigo is committed to providing
                  high-quality, friendly physicians, transparent pricing, and a
                  focus on building relationships and a lifestyle brand for
                  medical professionals nationwide.
                </Typography>
                <Box style={{ display: 'flex', gap: '7px' }}>
                  {[
                    {
                      href:
                        'https://www.facebook.com/profile.php?id=100064221304080',
                      src:
                        'https://medtigo.com/wp-content/uploads/2023/04/FB.svg'
                    },
                    {
                      href: 'https://twitter.com/medtigo?lang=en',
                      src:
                        'https://medtigo.com/wp-content/uploads/2023/04/X-transparent-1.svg'
                    },
                    {
                      href: 'http://instagram.com/medtigo/',
                      src:
                        'https://medtigo.com/wp-content/uploads/2023/04/Insta.svg'
                    },
                    {
                      href:
                        'https://www.youtube.com/channel/UCWtwjohak35fIMvUC8BzDKA',
                      src:
                        'https://medtigo.com/wp-content/uploads/2023/04/Youtube.svg'
                    },
                    {
                      href: 'https://www.linkedin.com/company/medtigo',
                      src:
                        'https://medtigo.com/wp-content/uploads/2023/04/linkedin.svg'
                    },
                    {
                      href: 'https://t.me/medtigocommunity',
                      src:
                        'https://medtigo.com/wp-content/uploads/2023/04/Telegram.svg'
                    }
                  ].map((icon, index) => (
                    <Box key={index}>
                      <Link href={icon.href}>
                        <div className={classes.socialIcon}>
                          <img
                            src={icon.src}
                            alt=""
                            style={{ width: '12px', height: '12px' }}
                          />
                        </div>
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box className={classes.MarginContainer}>
                <Typography variant="h6" className={classes.title}>
                  ABOUT
                </Typography>
                {[
                  {
                    href: 'https://medtigo.com/about_us_2023/',
                    text: 'About Us'
                  },
                  {
                    href: 'https://medtigo.com/term-of-use/',
                    text: 'Terms of Use'
                  },
                  {
                    href: 'https://medtigo.com/privacy-policy/',
                    text: 'Privacy Policy'
                  },
                  {
                    href: 'https://staffing.medtigo.com/provider-resources/',
                    text: 'Provider Resources'
                  },
                  { href: 'https://medtigo.com/sitemap/', text: 'Sitemap' }
                ].map((link, index) => (
                  <Box key={index}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{
                        display: 'flex',
                        gap: '15px',
                        alignItems: 'center'
                      }}
                    >
                      <img
                        className="me-3 list_arrow"
                        width="15px"
                        height="15px"
                        src="https://medtigo.com/wp-content/uploads/2023/04/Vector-15.svg"
                        alt=""
                      />
                      <Link href={link.href} className={classes.link}>
                        {link.text}
                      </Link>
                    </Typography>
                    {index < 4 && <hr className={classes.customHr} />}
                  </Box>
                ))}
              </Box>

              <Box className={classes.MarginContainer}>
                <Typography variant="h6" className={classes.title}>
                  ADDRESS
                </Typography>
                <Typography className={classes.addressTitle}>
                  MASSACHUSETTS – USA
                </Typography>
                <Typography className={classes.address}>
                  medtigo, LLC
                  <br />
                  60 Roberts Drive, Suite 301
                  <br />
                  North Adams, MA 01247
                </Typography>
                <Typography className={classes.addressTitle}>
                  MAHARASHTRA – INDIA
                </Typography>
                <Typography className={classes.address}>
                  H-5020, 5th floor, H-building.
                  <br />
                  Solitaire Business Hub,
                  <br />
                  Opp. Neco Garden Society,
                  <br />
                  Clover Park, Viman Nagar,
                  <br />
                  Pune, Maharashtra 411014
                </Typography>
              </Box>

              <Box className={`${classes.contactContainer} ${classes.MarginContainer}`}
              >
                <Typography variant="h6" className={classes.title}>
                  CONTACT
                </Typography>
                {[
                  {
                    href: 'tel:413-419-0592',
                    text: '413-419-0592',
                    label: 'Telephone: ',
                    noUnderline: true
                  },
                  {
                    href: 'mailto:support@medtigo.com',
                    text: 'support@medtigo.com',
                    label: 'General Inquiries: '
                  },
                  {
                    href: 'mailto:services@medtigo.com',
                    text: 'services@medtigo.com',
                    label: 'Medical Licensing: '
                  },
                  {
                    href: 'mailto:staffing@medtigo.com',
                    text: 'staffing@medtigo.com',
                    label: 'Locums Staffing: '
                  },
                  {
                    href: 'mailto:editor@medtigo.com',
                    text: 'editor@medtigo.com',
                    label: 'medtigo Journal: '
                  }
                ].map((contact, index) => (
                  <Box key={index} style={{ marginBottom: '0px !important' }}>
                    <Typography className={classes.contact}>
                      {contact.label}
                      <Link
                        href={contact.href}
                        className={
                          contact.noUnderline
                            ? classes.contactlinkNoUnderline
                            : classes.contactlink
                        }
                      >
                        {contact.text}
                      </Link>
                    </Typography>
                  </Box>
                ))}

                <Box
                  mt={2}
                  // style={{ position: 'relative' }}
                >
                  <img
                    src="/static/images/SocBadge.svg"
                    alt="SOC 2 Type 1 Certified"
                    width="298px"
                    style={{ marginLeft: '-19px', marginTop: '-12px' }}
                  />
                </Box>
                <Box className={classes.contactContainer}>
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    marginTop="-20px"
                  >
                    <Typography variant="body2" className={classes.downloadApp}>
                      DOWNLOAD APP
                    </Typography>
                    <Box
                      mt={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Link href="https://play.google.com/store/apps/details?id=com.medtigo&hl=en_IN&gl=US&pli=1">
                        <img
                          src="https://medtigo.com/wp-content/uploads/2023/04/playstore-1.png"
                          alt="Google Play Store"
                          className={classes.appLink}
                        />
                      </Link>
                      <Link href="https://apps.apple.com/us/app/medtigo/id1519512468">
                        <img
                          src="https://medtigo.com/wp-content/uploads/2023/04/PLAYSTOREW.png"
                          alt="Apple App Store"
                          className={classes.appLink}
                        />
                      </Link>
                      <Button
                        variant="contained"
                        className={classes.joinUs}
                        href="https://auth.medtigo.com/register"
                      >
                        JOIN US
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.copyright}>
          <Typography className={classes.copyrightText}>
            <img src='https://medtigo.com/wp-content/uploads/2024/08/Group-1000005825.svg' className={classes.CopyrightIcon} />
            COPYRIGHT 2024 MEDTIGO, ALL RIGHTS RESERVED
          </Typography>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
