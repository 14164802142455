import React from 'react';
import {Router,Route,Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import SimpleReactLightbox from "simple-react-lightbox";
import {create} from 'jss';
import rtl from 'jss-rtl';
import {ConfirmProvider} from 'material-ui-confirm';
import MomentUtils from '@date-io/moment';
import {SnackbarProvider} from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import {createTheme} from 'src/theme';
import Routes from 'src/Routes';
import './styles.css';
import {CertificatesContextProvider} from './context/CertificatesContext';
import {LearningContextProvider} from './context/LearningContext';
import {StaffingContextProvider} from "./context/StaffingContext";
import { MyLearningDataProvider } from './context/MyLearningContext';
import FeedbackView from './views/feedback';
import history from './utils/history';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    '.SRLPanzoomImage': {
      width: '40% !important',
    },
    '#SRLLightbox': {
      backgroundColor: 'rgba(111, 105, 105, 0.95)',
    },
    'iframe#launcher': {
      zIndex: '100 !important',
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiPickersCalendarHeader-transitionContainer': {
      cursor: 'pointer',
    },
    '.MuiPickersDatePickerRoot-toolbar': {
      display: 'none !important',
    },
  }
}));

function App() {

  const params = new URLSearchParams(window.location.search)
  const URL = window.location.href;
  if(params.get("hospitalId") && params.get("departmentId") && params.get("scheduleId")){
      const hosp = params.get("hospitalId")
      const depart = params.get("departmentId")
      const sch = params.get("scheduleId")
      localStorage.setItem("hospitalId", hosp)
      localStorage.setItem("departmentId", depart)
      localStorage.setItem("scheduleId", sch)
  }
  if(params.get("data") === "/redirect/checkout"){
    localStorage.setItem("add-to-cart", params.get("add-to-cart"))
    const lang = params.get("lang")
    if(lang){
      localStorage.setItem("lang", lang)
    }
    window.location.href = "https://connect.medtigo.com/redirect/checkout"
  }
  if(params.get("data") === "/notifications" && params.get("email") && params.get("route") && params.get("platform")){
    localStorage.setItem("data", params.get("data"))
    sessionStorage.setItem("email", params.get("email"))
    sessionStorage.setItem("route", params.get("route"))
    sessionStorage.setItem("platform", params.get("platform"))
  }
  
  if (URL.includes('reset')) {
    localStorage.setItem('reset', 'security');
    localStorage.setItem("medtigo",true)
  }

  if (URL.includes('feedback')) {
    localStorage.setItem('open_route', true);
  }
  if (URL.includes('onBoardingResendEmail')) {
    localStorage.setItem('open_route', true);
  }

  if (URL.includes('taskResponse')) {
    localStorage.setItem('open_route', true);
  }
  
  if (URL.includes('imageViewer')) {
    localStorage.setItem('open_route', true);
  }

  // if (URL.includes('unsubscribeMail')) {
  //   localStorage.setItem('open_route', true);
  // }

  if(params.get("path") === "certificates"){
    localStorage.setItem("certificateType", "certificates")
    window.location.href = "https://dev.medtigo.com/certificates"
  }
  if(params.get("path") === "cme"){
    localStorage.setItem("certificateType", "cme")
    window.location.href = "https://dev.medtigo.com/certificates"
  }


  useStyles();
  const [ok, _setOk] = React.useState(true)
  React.useEffect(() => {
    try {
      const whitelisted = ["medtigo", "localhost","connect.medtigo.com","qa.medtigo.com"]
      // we have to check the current domain
      if (whitelisted.includes(window.parent.location.hostname) || whitelisted.includes(window.parent.location.hostname.split(".")[1]) ) {
        // if the domain is correct we are good to go
        return _setOk(false);
      }} catch (err) {
      // otherwise ACCESS DENIED"
      return;
    }
    
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem("data")
    })
    return () => {
      window.removeEventListener('beforeunload', () => {
        localStorage.removeItem("data")
      });
      sessionStorage.clear();
      localStorage.removeItem("data")
    }
  }, [])
  const {settings} = useSettings();

if(ok){
  return <h1>ACCESS DENIED</h1>;
}
else{
  return (
    <SimpleReactLightbox>
      <ThemeProvider theme={createTheme(settings)}>
        <ConfirmProvider>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={1}>
                <Router history={history}>
                  <Auth>
                    <CertificatesContextProvider>
                      <LearningContextProvider>
                        <StaffingContextProvider>
                          <MyLearningDataProvider>
                            <ScrollReset/>
                            <GoogleAnalytics/>
                            <Routes/>
                          </MyLearningDataProvider>
                        </StaffingContextProvider>
                      </LearningContextProvider>
                    </CertificatesContextProvider>
                  </Auth>
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </SimpleReactLightbox>
  );
}


}

export default App;
