import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, makeStyles } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const navbarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    marginTop: 10,
    // height: '38px',
    fontSize: '16px',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    }
  },
  tab: {
    color: '#000',
    textDecoration: 'none',
    fontSize: '18px',
    minWidth: 'auto',
    width: 'auto',
    padding: '0px !important',
    marginRight: '20px !important',
    minHeight: '40px',
    '&.Mui-selected': {
      color: '#2872C1',
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingInline: '12px'
    }
  },
  tabIndicator: {
    backgroundColor: '#2872C1',
    height: '3px',
    bottom: 0,
  },
}));

function SchedulingNavBar() {
  const classes = navbarStyles();
  const location = useLocation();
  const history = useHistory();
  const { showSchedulingPayment } = useSelector(state => state.account.user);
  const [currentTab, setCurrentTab] = useState('my schedule');

  const tabs = showSchedulingPayment ? [
    { value: 'my schedule', label: 'My Schedule', link: '/schedule/my-schedule' },
    { value: 'group schedule', label: 'Group Schedule', link: '/schedule/group-schedule' },
    { value: 'availability', label: 'Availability', link: '/schedule/availability' },
    { value: 'swap request', label: 'Swap Request', link: '/schedule/swap-request' },
    { value: 'payment request', label: 'Payment Request', link: '/schedule/payment-request' },
    { value: 'payment history', label: 'Payment History', link: '/schedule/payment-history' }
  ] : [
    { value: 'my schedule', label: 'My Schedule', link: '/schedule/my-schedule' },
    { value: 'group schedule', label: 'Group Schedule', link: '/schedule/group-schedule' },
    { value: 'availability', label: 'Availability', link: '/schedule/availability' },
    { value: 'swap request', label: 'Swap Request', link: '/schedule/swap-request' }
  ];

  useEffect(() => {
    const currentTab = tabs.find(tab => tab.link === location.pathname);
    if(location.pathname.includes('payment-request-details')){
      setCurrentTab('payment history');
    }
    if (currentTab) {
      setCurrentTab(currentTab.value);
    }
  }, [location.pathname]);

  const handleTabsChange = (event, newValue) => {
    setCurrentTab(newValue);
    const newTab = tabs.find(tab => tab.value === newValue);
    if (newTab) {
      history.push(newTab.link);
    }
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={currentTab}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons="auto"
        classes={{ indicator: classes.tabIndicator }}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            className={classes.tab}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default SchedulingNavBar;
