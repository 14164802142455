import * as React from 'react';
import certificatesService from "../services/certificatesService";
import {updateCard} from "../actions/kanbanActions";
import {useSnackbar} from "notistack";
import RenewOrderServices from 'src/services/renewOrderService';
import { CertificateVariants, Partners } from 'src/appConstants';
import { useSelector} from 'react-redux';
import orderServices from 'src/services/orderServices';
import calculateCreditPoints from 'src/utils/calculateCreditPoints';
import alertPage from 'src/services/alertPage';
import { groupBy } from 'lodash';

export const CertificatesContext = React.createContext({});

export const CertificatesContextProvider = ({ children }) => {
  const user = useSelector((state) => state.account.user);
  const {enqueueSnackbar} = useSnackbar();
  const [drawerStatus, setDrawerStatus] = React.useState(false);
  const [addedCertificate, setAddedCertificate] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeCertificateData, setActiveCertificateData] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = React.useState(false);
  const [highlightedCertID, setHighlightedCertID] = React.useState(null);
  const [isACLSOrderExist, setACLSOrder] = React.useState(false);
  const [isBLSOrderExist, setBLSOrder] = React.useState(false);
  const [isPALSOrderExist, setPALSOrder] = React.useState(false);
  const [isASLSOrderExist, setASLSOrder] = React.useState(false);
  const [isNRPOrderExist, setNRPOrder] = React.useState(false);
  const [partnerUser, setPartnerUser] = React.useState(false); // FOR TEAMHEALTH USER
  const [noACLSCertificateExist, setNoACLSCertificate] = React.useState(false);
  const [noBLSCertificateExist, setNoBLSCertificate] = React.useState(false);
  const [noPALSCertificateExist, setNoPALSCertificate] = React.useState(false);
  const [noASLSCertificateExist, setNoASLSCertificate] = React.useState(false);
  const [noNRPCertificateExist, setNoNRPCertificate] = React.useState(false);

  const [isACLS_CME_available, setACLS_CME_Available] = React.useState(false);
  const [isBLS_CME_available, setBLS_CME_Available] = React.useState(false);
  const [isOpioid_CME_Available, setOpioid_CME_Available] = React.useState(false);
  const [isPALS_CME_available, setPALS_CME_Available] = React.useState(false);
  const [isASLS_CME_available, setASLS_CME_Available] = React.useState(false);
  const [isNRP_CME_available, setNRP_CME_Available] = React.useState(false);

  const [isACLS_Order_Hash, setACLS_Order_Hash] = React.useState(null);
  const [isBLS_Order_Hash, setBLS_Order_Hash] = React.useState(null);
  const [isOpioid_Order_Hash, setOpioid_Order_Hash] = React.useState(null);
  const [isPALS_Order_Hash, setPALS_Order_Hash] = React.useState(null);
  const [isASLS_Order_Hash, setASLS_Order_Hash] = React.useState(null);
  const [isNRP_Order_Hash, setNRP_Order_Hash] = React.useState(null);

  const [isACLS_Exist, setACLS_Exist] = React.useState(true);
  const [isBLS_Exist, setBLS_Exist] = React.useState(true);
  const [isPALS_Exist, setPALS_Exist] = React.useState(true);
  const [isASLS_Exist, setASLS_Exist] = React.useState(true);
  const [isNRP_Exist, setNRP_Exist] = React.useState(true);

  const [isFullAccess, setFull_Access] = React.useState(false);
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [certificatesData, setCertificatesData] = React.useState([]);
  const [grantedLicenses, setGrantedLicenses] = React.useState([]);
  const [CMECertificatesData, setCMECertificatesData] = React.useState([]);
  const [stepperCMECertificates, setStepperCMECertificates] = React.useState([]);
  const [latestCertIds, setLatestCertIds] = React.useState([]);

  // For Dashboard
  const [generatedCertificates, setGeneratedCertificates] = React.useState([]);
  const [addedCertificates, setAddedCertificates] = React.useState([]);
  const [oldCertificates, setOldCertificates] = React.useState([]);

  const [creditPoints, setCreditPoints] = React.useState(0);

  const [claimedCreditPoints, setClaimedCreditPoints] = React.useState(0);
  const [userAddedCreditPoints, setUserAddedCreditPoints] = React.useState(0);
  const [cmeClaimPendingCount, setCMEClaimPendingCount] = React.useState(0);
  const [claimed, setClaimed] = React.useState(0);
  const [canGeneratePCard, setCanGeneratePCard] = React.useState(false);

  const categoriseCerts=(certificatesData)=>{
    const bls_all = (certificatesData.filter(it=>it.certificate_name_id=='3993767000000047807' && it['entered_from_frontend']==0) || []).reverse(); 
    const acls_all = (certificatesData.filter(it=>it.certificate_name_id=='3993767000000047791' && it['entered_from_frontend']==0) || []).reverse(); 
    const pals_all = (certificatesData.filter(it=>it.certificate_name_id=='3993767000000047799' && it['entered_from_frontend']==0) || []).reverse();
    // reversing array will get latest element on 0th index 
    let tempArr = [
      bls_all.length>0? bls_all[0].id:null,
      acls_all.length>0? acls_all[0].id:null,
      pals_all.length>0? pals_all[0].id:null,
    ] 
    // tempArr Array Contains all latest (Most Recent) Certificates of ACLS, BLS, PALS
    setLatestCertIds(tempArr)
  }

  const fetchDummyCertificates = async () => {
    const dummyCertificates = await certificatesService.getDummyCertificates();
    return dummyCertificates
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let certificatesData = await certificatesService.getUserCertificates();
      categoriseCerts(certificatesData);
      const data = certificatesData.sort(function (a, b) { return b.entered_from_frontend - a.entered_from_frontend }).reverse();
      setCertificatesData(data);

      // Filter only medtigo course provider cards
      const filteredCertificates = data.filter(item => item.entered_from_frontend === 0 && item.state === 'Education');

      // Group filtered certificates by certificate_name
      const groupedCertificates = groupBy(filteredCertificates, 'certificate_name');

      // Combine the certificate with the latest Date_of_Creation from each group
      const firstCertificates = Object.values(groupedCertificates).map(certificates =>
        certificates.sort((a, b) => new Date(b.Date_of_Creation) - new Date(a.Date_of_Creation))[0]
      );

      // Exclude the latest certificate for each course based on Date_of_Creation
      const oldCertificates = Object.values(groupedCertificates)
        .flatMap(certificates =>
          certificates
            .filter(cert => cert.entered_from_frontend === 0) // Exclude certificates with entered_from_frontend = 1
            .sort((a, b) => new Date(b.Date_of_Creation) - new Date(a.Date_of_Creation)) // Sort by Date_of_Creation in descending order
            .slice(1) // Exclude the latest certificate
        )
        .filter(item => !item.certificate_name.includes('License'));

      const states = ['Education', 'Health', 'Malpractice', 'Transcripts and Scores'];

      setGeneratedCertificates(firstCertificates);
      setAddedCertificates(data.filter(certificate => certificate.entered_from_frontend === 1 && states.includes(certificate.state)));
      setOldCertificates(oldCertificates);

    } catch (error) {
      console.log(error);
      setCertificatesData([]);
    }
    try {
      const { data: grantedData } = await alertPage.getGrantedActiveLicenses(
        user.id
      );
      setGrantedLicenses(grantedData.filter(item => item.entered_from_frontend == false));
    } catch (error) {
      console.log(error);
      setGrantedLicenses([]);
    }

    try {
      // Fetch CME Certificates
      const CMECertificatesData = await certificatesService.getUserCertificates('cme');
      setCMECertificatesData(CMECertificatesData);
      setCreditPoints(calculateCreditPoints(CMECertificatesData));
      setClaimedCreditPoints(calculateCreditPoints(CMECertificatesData.filter(item => item.entered_from_frontend === 0)));
      setUserAddedCreditPoints(calculateCreditPoints(CMECertificatesData.filter(item => item.entered_from_frontend === 1)));
    } catch (error) {
      console.log(error);
      setCMECertificatesData([]);
      setCreditPoints(0);
    }  

    try {
      // Fetch Stepper CME Certificates
      const StepperCertificates = await certificatesService.getDummyCertificates();
      setStepperCMECertificates(StepperCertificates);
    } catch (error) {
      console.log(error);
      setStepperCMECertificates([]);
    }  
    setIsLoading(false);
  };

  const handleSendEmail = async (emails, sendACopy, isCME, shouldEmailCmeCredits,shouldEmailCertificate) => {
    try {
      if (isCME) {
        if (shouldEmailCmeCredits) {
          await certificatesService.sendCertificateEmail(
            { recipients: emails.join(','), sendACopy, isCME: true },
            activeCertificateData['id']
          );
        }
        if (shouldEmailCertificate) {
          await certificatesService.sendCertificateEmail(
            { recipients: emails.join(','), sendACopy, isCME: false },
            activeCertificateData['nonCMECertId']
          );
        }
        enqueueSnackbar('Email(s) sent to recipients.', {
          variant: 'success'
        });
        return;
      }
      await certificatesService.sendCertificateEmail(
        {
          recipients: emails.join(','),
          sendACopy,
          isCME:
            activeCertificateData['type'] === 'CME' ||
            activeCertificateData['type'] === 'cme'
        },
        activeCertificateData['id']
      );
      enqueueSnackbar('Email(s) sent to recipients.', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar('Oops! An error occurred while sending emails.', {
        variant: 'error'
      });
    }

  };

  React.useEffect(() => {
    !drawerStatus && fetchData();
    drawerStatus && !isEdit && setActiveCertificateData({});
    drawerStatus && setHighlightedCertID(null);
  }, [addedCertificate]);

  React.useEffect(() => {
    setCreditPoints(calculateCreditPoints(CMECertificatesData));
  }, [CMECertificatesData]);

  React.useEffect(() => {
    isCertificateExist();
  }, [claimed])

  const isCertificateExist = async () => {
    if(user?Partners.includes(user.email.split('@')[1]):false){
      setPartnerUser(true);
      return;
    }

    const isFullAccessOrder = await orderServices.isFullAccessOrder();

    setFull_Access(isFullAccessOrder.isFullAccess);

    const PALS_Course_id = CertificateVariants.find(it => it.name == 'PALS').Course_id;
    const PALS_Order = await RenewOrderServices.isOrderItemWithValidHash(PALS_Course_id);
    setPALS_CME_Available(PALS_Order.isCMEValid);
    setPALS_Order_Hash(PALS_Order.hash);

    const ACLS_Course_id = CertificateVariants.find(it => it.name == 'ACLS').Course_id;
    const ACLS_Order = await RenewOrderServices.isOrderItemWithValidHash(ACLS_Course_id);

    setACLS_CME_Available(ACLS_Order.isCMEValid);
    setACLS_Order_Hash(ACLS_Order.hash);

    const BLS_Course_id = CertificateVariants.find(it => it.name == 'BLS').Course_id;
    const BLS_Order = await RenewOrderServices.isOrderItemWithValidHash(BLS_Course_id);
    setBLS_CME_Available(BLS_Order.isCMEValid);
    setBLS_Order_Hash(BLS_Order.hash);

    const Opioid_Order = await RenewOrderServices.isOrderItemWithValidHash(11159);
    setOpioid_CME_Available(Opioid_Order.isCMEValid);
    setOpioid_Order_Hash(Opioid_Order.hash);

    let pendingCount = 0;
    if (ACLS_Order.isCMEValid && ACLS_Order.hash) pendingCount += 4.25;
    if (BLS_Order.isCMEValid && BLS_Order.hash) pendingCount += 3;
    if (PALS_Order.isCMEValid && PALS_Order.hash) pendingCount += 4;
    if (Opioid_Order.hash) pendingCount += 3;
    setCMEClaimPendingCount(pendingCount);

    const NRP_Course_id = CertificateVariants.find(it => it.name == 'NRP').Course_id;
    const NRP_Order = await RenewOrderServices.isOrderItemWithValidHash(NRP_Course_id);
    setNRP_Order_Hash(NRP_Order.hash);

    const ASLS_Course_id = CertificateVariants.find(it => it.name == 'ASLS').Course_id;
    const ASLS_Order = await RenewOrderServices.isOrderItemWithValidHash(ASLS_Course_id);
    setASLS_Order_Hash(ASLS_Order.hash);

    const res = await certificatesService.ifCertificateExist();
    if (res.data.PALS_Exist.length <= 0) {
      setPALS_Exist(false);
      setNoPALSCertificate(true);
      setPALSOrder(PALS_Order.isValidOrder); 
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'PALS').id);
      if (renewHistory && renewHistory.length > 0) {
        setPALSOrder(true);
      }
    }
    if (res.data.ACLS_Exist.length <= 0) {
      setACLS_Exist(false);
      setNoACLSCertificate(true);
      setACLSOrder(ACLS_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'ACLS').id);
      if (renewHistory && renewHistory.length > 0) {
        setACLSOrder(true);
      }
    }
    if (res.data.BLS_Exist.length <= 0) {
      setBLS_Exist(false);
      setNoBLSCertificate(true);
      setBLSOrder(BLS_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'BLS').id);
      if (renewHistory && renewHistory.length > 0) {
        setBLSOrder(true);
      }
    }
    if (res.data.NRP_Exist.length <= 0) {
      setNRP_Exist(false);
      setNoNRPCertificate(true);
      // setNRPOrder(NRP_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'NRP').id);
      if (renewHistory && renewHistory.length > 0) {
        setNRPOrder(true);
      }
    }

    if (res.data.ASLS_Exist.length <= 0) {
      setASLS_Exist(false);
      setNoASLSCertificate(true);
      // setASLSOrder(ASLS_Order.isValidOrder);
    } else {
      const renewHistory = await RenewOrderServices.getRenewalFalseRecords(CertificateVariants.find(it => it.name == 'ASLS').id);
      if (renewHistory && renewHistory.length > 0) {
        setASLSOrder(true);
      }
    }
  }

  const setGetCertificateButton=(value)=>{
    setCanGeneratePCard(value)
  }

  const defaultContext = {
    drawerStatus,
    setDrawerStatus,
    fetchData,
    isLoading,
    setIsLoading,
    certificatesData,
    grantedLicenses,
    CMECertificatesData,
    stepperCMECertificates,
    generatedCertificates,
    addedCertificates,
    oldCertificates,
    latestCertIds,
    setLatestCertIds,
    setCreditPoints,
    creditPoints,
    claimedCreditPoints,
    setClaimed,
    userAddedCreditPoints,
    cmeClaimPendingCount,
    addedCertificate,
    setAddedCertificate,
    activeCertificateData,
    setActiveCertificateData,
    isEdit,
    setIsEdit,
    setIsEmailDialogOpen,
    isEmailDialogOpen,
    handleSendEmail,
    highlightedCertID,
    setHighlightedCertID,
    activeTab,
    setActiveTab,
    isACLSOrderExist,
    isBLSOrderExist,
    isPALSOrderExist,
    noACLSCertificateExist,
    noBLSCertificateExist,
    noPALSCertificateExist,
    noNRPCertificateExist,
    partnerUser,
    isACLS_CME_available,
    isBLS_CME_available,
    isPALS_CME_available,
    isACLS_Order_Hash,
    isBLS_Order_Hash,
    isPALS_Order_Hash,
    isCertificateExist,
    isFullAccess,
    isACLS_Exist,
    isBLS_Exist,
    isPALS_Exist,
    isNRP_Exist,
    isNRPOrderExist,
    isNRP_CME_available,
    isNRP_Order_Hash,
    noASLSCertificateExist,
    isASLS_Exist,
    isASLSOrderExist,
    isASLS_CME_available,
    isASLS_Order_Hash,
    fetchDummyCertificates,
    canGeneratePCard,
    setGetCertificateButton
  };

  return (
    <CertificatesContext.Provider
      value={defaultContext}
    >
      {children}
    </CertificatesContext.Provider>
  );
};
